:root {
  --primary-color: #f8c708;
  --gray-color: #f8f8f8;
}

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 1rem;
    font-family: "Sora", "Rubik", "Sofia Sans Condensed", Nunito, Montserrat,
        "HK Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    line-height: 160%;
}

.btn-primary{
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: black;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-gray {
  background-color: var(--gray-color) !important;
}

.counte{
  top: -5px;
  right: -5px;
  font-size: 10px;
  font-weight: bold;
  background-color: var(--primary-color);
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.w-60{
  width: 25%;
}

.index-carousel{
  z-index: -10;
}

.text-primary{
  color: var(--primary-color) !important;
}